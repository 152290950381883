// VideoModal.js
import React from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

Modal.setAppElement('#root'); // Make sure to set the root element for accessibility

const VideoModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Watch Video"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-content">
        <video controls autoPlay>
          <source src="/images/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Modal>
  );
};

export default VideoModal;
