import "../styles/footer.css";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { TbPhone } from "react-icons/tb";
import { TbMail } from "react-icons/tb";
import { TbLocation } from "react-icons/tb";

export default function Footer() {
  return (
    <footer>
      <div class="mx-auto w-full">
        <div class="container py-16 md:flex space-x-0 lg:space-x-10 md:justify-between">
          <div class="w-full md:w-4/12 mb-14 md:mb-0">
            <h5>About Reports Detective</h5>
            <p>
              Reports Detective is a business intelligence and AI insight software developed by <a target="_blank" className="hover:underline text-blue-500" href="https://perplexityonline.com/">Perplexity</a>. Designed to empower organizations with advanced analytics, it transforms data into actionable insights, helping users make informed decisions with confidence.
            </p>
          </div>
          <div className="w-full md:w-8/12">
            <div class="grid grid-cols-1 gap-10 sm:grid-cols-3">
              <div>
                <h5>Useful Links</h5>
                <ul class="space-y-2 lg:space-y-3">
                  <li>
                    <Link to="/#download" class="hover:underline">
                      Downloads
                    </Link>
                  </li>
                  <li>
                    <a target="_blank" href="https://perplexitymeet.com/#plans" class="hover:underline">
                      Plans
                    </a>
                  </li>
                  <li>
                    <Link to="/#features" class="hover:underline">
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link to="/#clients" class="hover:underline">
                      Clients
                    </Link>
                  </li>
                  <li>
                    <a target="_blank" href="https://staging2.reportsdetective.com/login" class="hover:underline">
                      Sign In
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h5>Social Links</h5>
                <ul class="space-y-2 lg:space-y-3">
                  <li>
                    <a target="_blank" href="https://www.facebook.com/PerplexityMeet1" class="hover:underline">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/showcase/perplexitymeet/about/" class="hover:underline">
                      Linked In
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://twitter.com/PerplexityMeet" class="hover:underline">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://perplexityonline.com/" class="hover:underline">
                      Perplexity
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h5>Contact</h5>
                <ul class="space-y-3 lg:space-y-3">
                  <li className="flex flex-row">
                    <TbLocation size={20} />{" "}
                    <span className="ml-2 -mt-0.5">
                      Perplexity
                      <br />
                      5851 Legacy Cir
                      <br />
                      Plano, Tx 75024
                    </span>
                  </li>
                  <li className="flex flex-row items-center">
                    <TbMail size={22} /> <span className="ml-1">hello@perplexitymeet.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-main">
          <div class="container mx-auto sm:flex sm:items-center sm:justify-between py-6">
            <span class="text-sm sm:text-center text-white">
              <Link to="/" class="hover:underline site-title">
                PERPLEXITY
              </Link>{" "}
              - A Registered Trademark of Perplexity Solved Solutions Inc. All Rights Reserved.
            </span>
            <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0 text-sm">
              <Link to="/privacy" class="text-white hover:underline">
                Privacy Policy
              </Link>
              <Link to="/terms" class="text-white hover:underline">
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
